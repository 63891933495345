import { usePermissions } from 'framework';
import { memo } from 'react';

const Authorization = ({ children, permission, shouldShow }: { children: JSX.Element; permission?: string; shouldShow?: boolean }) => {
  const { permissions } = usePermissions();

  if (permission && !permissions?.find((p) => p.name === permission && p.enabled === true)) {
    return null;
  }

  if (shouldShow === false) {
    return null;
  }

  return children;
};

export default memo(Authorization);
