import { Permissions } from '@/utils/permissions';
import { useInternalUsers, usePermissions } from 'framework';

export default function useShowHome() {
  const { permissions, loading } = usePermissions();
  const { isInternalUser } = useInternalUsers();

  const canViewTransactions = permissions?.some((permission) => permission.name === Permissions.transactionsAllView && permission.enabled);
  const canViewTransfers = permissions?.some((permission) => permission.name === Permissions.transfersAllView && permission.enabled);
  const canViewBilling = permissions?.some((permission) => permission.name === Permissions.billingInvoiceView && permission.enabled);

  if (!(canViewTransactions || canViewTransfers || canViewBilling)) {
    return { loading, shouldShowHome: isInternalUser ? true : false, canViewTransactions, canViewTransfers, canViewBilling };
  }

  return { loading, shouldShowHome: true, canViewTransactions, canViewTransfers, canViewBilling };
}
