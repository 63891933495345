import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Pseudo from 'i18next-pseudo';
import resourcesToBackend from 'i18next-resources-to-backend';

const translations = resourcesToBackend((language, namespace, callback) => {
  if (language === 'is') {
    return callback(null, null);
  }

  import(`./locale/${language}/${namespace}.json`)
    .then((messages) => {
      callback(null, messages.default);
    })
    .catch((e) => {
      callback(e, null);
    });
});

const configureI18n = async (_environment: string, supportedLanguages: string[]) => {
  await i18n
    .use(translations)
    .use(LanguageDetector)
    .use(
      new Pseudo({
        enabled: true,
        languageToPseudo: 'is',
      })
    )
    .init(
      {
        supportedLngs: supportedLanguages ? supportedLanguages || ['en'] : ['en'],
        fallbackLng: 'en',
        defaultNS: 'common',
        detection: {
          order: ['querystring', 'navigator'],
        },
        keySeparator: false,
        debug: false,
        postProcess: ['pseudo'],
        ns: [
          'account_details',
          'accounting_integrations',
          'business_funding',
          'billing',
          'card_machines',
          'global',
          'my_details',
          'locations',
          'refer_a_friend',
          'pci_compliance',
          'products',
          'register_user',
          'login',
          'error',
          'transfers',
          'team',
          'queues_bookings',
          'payments',
          'home',
          'transactions',
          'insights',
          'internal_user',
          'pay_by_qr',
          'onboarding',
          'card',
          'invites',
        ],
      },
      (err) => {
        if (err) {
          console.error('Something went wrong initialising i18n package', err);
        }
      }
    );
};

export { configureI18n, i18n, translations };
