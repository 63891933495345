import { DjBodyText } from '@paymentsense/dj-components-react';
import { useAuth, useCustomers } from 'framework';
import { LoadingPage } from 'legacy-components';
import { i18n } from 'legacy-i18n';
import { DjpNavFooter } from 'legacy-stencil-react';

export const Footer = () => {
  const { customer } = useCustomers();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  // this is a workaround due to styling issues with the footer when the demo account banner is shown.
  // specifically the log out button gets pushed off the screen. This hack should be removed when the
  // navigation is refactored to use all React components (specifically DjpNavFooter)
  const showDemoAccountBanner = customer?.isSandbox === true;
  const position = showDemoAccountBanner ? 'fixed' : 'relative';

  return (
    <div slot="footer" style={{ display: 'block', position }}>
      {!customer ? (
        <LoadingPage />
      ) : (
        <div style={{ backgroundColor: 'var(--app-grey)', display: 'flex', flexDirection: 'column', padding: '12px 24px' }}>
          <span style={{ fontWeight: 400, fontSize: '12px', lineHeight: '16px', color: 'var(--app-black-alpha-60)' }}>
            {i18n.t('global:global.navigation.customer_reference_number_label')}
          </span>
          <DjBodyText>{customer.customerReference}</DjBodyText>
        </div>
      )}
      <DjpNavFooter logoutCallback={handleLogout} />
    </div>
  );
};
