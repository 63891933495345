import { useNumberOfCardMachinesQuery } from '@/graphql/generated';
import { useCustomers } from 'framework';
import { useMemo } from 'react';

export default function useShowCardMachine(locationId: string | null = null) {
  const { customer } = useCustomers();
  const { customerId } = customer ?? {};

  const { data, loading, error } = useNumberOfCardMachinesQuery({
    variables: { customerId },
    fetchPolicy: 'cache-first',
    skip: !customerId,
  });

  const shouldShowCardMachines = useMemo(() => {
    if (!data?.customerLocations) return false;

    if (locationId === '0') {
      return data.customerLocations.some((location) => location.numberOfCardMachines > 0);
    } else if (locationId) {
      const location = data.customerLocations.find((loc) => loc.locationId === locationId);
      return location ? location.numberOfCardMachines > 0 : false;
    }

    return false;
  }, [data, locationId]);

  return { shouldShowCardMachines, loading, error };
}
