import { RoutesWithoutNavigationMenu } from '@/utils/routes';
import { useCustomers } from 'framework';
import { AppBanner } from 'legacy-components';
import Router from 'next/router';
import DemoAccountBanner from '../demoAccountBanner/demoAccountBanner';
import { Header } from '../header/header';
import Navigation from '../navigation';
import styles from './layout.module.css';

import { androidAppUrl, APP_BANNER_VIEWED_COOKIE, iosAppUrl } from '@/config/nativeApps';
import { ReactNode } from 'react';

export const Layout = ({ children }: { children: ReactNode }) => {
  const { customer } = useCustomers();

  if (RoutesWithoutNavigationMenu.some((route) => Router.pathname.startsWith(route))) {
    return children;
  }

  const showDemoAccountBanner = customer?.isSandbox === true;

  return (
    <div className={styles.layoutContainer}>
      <section className={styles.appBanner}>
        {showDemoAccountBanner ? (
          <DemoAccountBanner />
        ) : (
          <AppBanner viewedCookieId={APP_BANNER_VIEWED_COOKIE} appUrlIos={iosAppUrl} appUrlAndroid={androidAppUrl} />
        )}
      </section>
      <aside className={styles.aside}>
        <Navigation />
      </aside>
      <header className={styles.header}>
        <Header />
      </header>
      <main id="main" className={styles.content}>
        {children}
      </main>
    </div>
  );
};

export default Layout;
