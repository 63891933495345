import { BusinessFundingStateQuery, BusinessFundingStatus, useBusinessFundingStateQuery } from '@/graphql/generated';
import { useCustomers } from 'framework';
import { useEffect, useMemo, useState } from 'react';

export default function useShowBusinessFunding() {
  const [businessFunding, setBusinessFunding] = useState<BusinessFundingStateQuery>(undefined);
  const { customer } = useCustomers();
  const { customerId } = customer ?? {};
  const { data: businessFundingData } = useBusinessFundingStateQuery({ variables: { customerId }, fetchPolicy: 'cache-first', skip: !customerId });

  useEffect(() => {
    if (!businessFunding) {
      setBusinessFunding(businessFundingData);
    }
  }, [businessFunding, businessFundingData]);

  const shouldShowBusinessFunding = useMemo(() => businessFunding?.businessFundingStateV2?.status !== BusinessFundingStatus.InEligible, [businessFunding]);

  return shouldShowBusinessFunding;
}
