import { config } from '@/config';
import { RoutesNoAuth } from '@/utils/routes';
import { LegacyProvidersNoAuth } from 'legacy-framework';
import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';

type Props = {
  children: JSX.Element;
  noAuthChildren: JSX.Element;
};

function ProtectedPages({ children, noAuthChildren }: Props): JSX.Element {
  const { pathname } = useRouter();
  const renderProtectedPages = useMemo(() => {
    return !RoutesNoAuth.some((route) => pathname === route);
  }, [pathname]);

  /* TODO: only mount LegacyProviders for Stencil features */
  return renderProtectedPages ? (
    children
  ) : (
    <LegacyProvidersNoAuth featureFlags={config.featureFlags} userIdentityGatewayPath={config.userIdentityGatewayPath}>
      {noAuthChildren}
    </LegacyProvidersNoAuth>
  );
}

export default memo(ProtectedPages);
