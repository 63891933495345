import { QueueBookingEligibilityQuery, useQueueBookingEligibilityQuery } from '@/graphql/generated';
import { ExperimentFlagsConstants } from '@/utils/experimentFlags';
import { Permissions } from '@/utils/permissions';
import { useCustomers, useLaunchDarkly, usePermissions } from 'framework';
import { useEffect, useMemo, useState } from 'react';

export default function useShowPayByQr() {
  const { customer } = useCustomers();
  const { customerId } = customer ?? {};
  const { getFlagValue } = useLaunchDarkly();
  const [queuesAndBooking, setQueuesAndBooking] = useState<QueueBookingEligibilityQuery>(undefined);
  const { data: queuesAndBookingData } = useQueueBookingEligibilityQuery({ variables: { customerId }, fetchPolicy: 'cache-first', skip: !customerId });
  const { permissions } = usePermissions();

  useEffect(() => {
    if (!queuesAndBooking) {
      setQueuesAndBooking(queuesAndBookingData);
    }
  }, [queuesAndBooking, queuesAndBookingData]);

  const shouldShowPayByQr = useMemo(
    () =>
      !!queuesAndBooking?.queueBookingEligibility &&
      !!getFlagValue(ExperimentFlagsConstants.payByQr) === true &&
      !!permissions?.some((p) => p.name === Permissions.productAllOnboard && p.enabled === true),
    [getFlagValue, queuesAndBooking, permissions]
  );

  return shouldShowPayByQr;
}
