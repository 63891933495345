import { useMarketplaceProductIdsQuery } from '@/graphql/generated';
import { useCustomers } from 'framework';
import { createContext, ReactNode, useMemo } from 'react';

type EligibleProductsContextProps = {
  loading: boolean;
  products?: string[];
};

export const EligibleProductsContext = createContext<EligibleProductsContextProps>({
  loading: false,
  products: [],
});

export interface EligibleProductsProviderProps {
  children: ReactNode;
}

export const EligibleProductsProvider = ({ children }: EligibleProductsProviderProps) => {
  const { loading: customerLoading, customer: customer } = useCustomers();

  const { data, loading } = useMarketplaceProductIdsQuery({
    skip: customerLoading || !customer?.customerId,
    variables: { customerDataInput: { customerId: customer?.customerId } },
    fetchPolicy: 'cache-first',
  });

  const value: EligibleProductsContextProps = useMemo(
    () => ({
      loading: customerLoading || loading,
      products: data?.marketplaceProductsV2?.map((product) => product.id) ?? [],
    }),
    [loading, customerLoading, data?.marketplaceProductsV2]
  );
  return <EligibleProductsContext.Provider value={value}>{children}</EligibleProductsContext.Provider>;
};
