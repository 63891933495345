import { DjNav } from '@paymentsense/dj-components-react';
import { useBraze, useCustomers, usePermissions } from 'framework';
import { LoadingPage } from 'legacy-components';
import { memo, useEffect, useMemo } from 'react';
import ContentCard from '../contentCard/contentCard';
import { Footer } from '../footer';
import styles from '../navigation.module.css';
import NavigationItems from '../navItems/navItems';
import { SwitchAccount } from '../switchAccount';

const CustomerNav = () => {
  const { loading: loadingCustomers, customer } = useCustomers();
  const { loading: loadingPermissions, permissions } = usePermissions();
  const { accountCards, getContentCards } = useBraze();
  const loading = useMemo(
    () => !customer || !permissions || loadingCustomers || loadingPermissions,
    [customer, loadingCustomers, loadingPermissions, permissions]
  );

  useEffect(() => {
    // NOTE: This fetches Braze's content cards for the homepage and the navigation drawer
    getContentCards();
  }, [getContentCards]);

  return (
    <DjNav className={styles.nav}>
      <SwitchAccount />
      {accountCards.map((card) => (
        <ContentCard key={card?.id} contentCard={card} />
      ))}
      {loading ? <LoadingPage count={5} /> : <NavigationItems />}
      <Footer />
    </DjNav>
  );
};

export default memo(CustomerNav);
