import { FEATURE_RMS, FEATURE_RMS_ADDITIONAL_LOCATIONS } from '@/utils/launchDarklyFlags';
import { Permissions } from '@/utils/permissions';
import { useCustomers, usePermissions } from 'framework';

// FIXME: relative URL - should be a package import
import { useLaunchDarkly } from 'framework';
import { PRODUCT_IDS } from '../../../../packages/legacy-stencil/src/constants/productConstants';
import useEligibleProducts from './useEligibleProducts';

export default function useShowNewQueueBooking() {
  const { customer } = useCustomers();
  const { getFlagValue } = useLaunchDarkly();
  const { permissions } = usePermissions();
  const { products } = useEligibleProducts();

  const singleLocation = customer?.locations?.length === 1;
  const productAllOnboardPermissionEnabled = permissions?.some((p) => p.name === Permissions.productAllOnboard && p.enabled === true) ?? false;
  const productInMarketplace = products.some((productId) => productId === PRODUCT_IDS.RMS) ?? false;
  const featureFlagEnabled = getFlagValue(FEATURE_RMS) === true;
  const additionalLocationFeatureFlagEnabled = getFlagValue(FEATURE_RMS_ADDITIONAL_LOCATIONS) === true;

  return productAllOnboardPermissionEnabled && productInMarketplace && featureFlagEnabled && (additionalLocationFeatureFlagEnabled || singleLocation);
}
