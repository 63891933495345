import { QueueBookingEligibilityQuery, useQueueBookingEligibilityQuery } from '@/graphql/generated';
import { ExperimentFlagsConstants } from '@/utils/experimentFlags';
import { useCustomers, useLaunchDarkly } from 'framework';
import { useEffect, useMemo, useState } from 'react';

export default function useShowQueueBooking() {
  const { customer } = useCustomers();
  const { customerId } = customer ?? {};
  const { getFlagValue } = useLaunchDarkly();
  const [queuesAndBooking, setQueuesAndBooking] = useState<QueueBookingEligibilityQuery>(undefined);
  const { data: queuesAndBookingData } = useQueueBookingEligibilityQuery({ variables: { customerId }, fetchPolicy: 'cache-first', skip: !customerId });

  useEffect(() => {
    if (!queuesAndBooking) {
      setQueuesAndBooking(queuesAndBookingData);
    }
  }, [queuesAndBooking, queuesAndBookingData]);

  const shouldShowQueueBooking = useMemo(
    () => queuesAndBooking?.queueBookingEligibility && getFlagValue(ExperimentFlagsConstants.virtualQueuesAndBookings) === true,
    [getFlagValue, queuesAndBooking]
  );

  return shouldShowQueueBooking;
}
